import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';
import React, { useState } from 'react';

function CenterText() {

  const [helloLanguage, setHelloLanguage] = useState(0);
  const languages = ["Hi!", "你好!", "hola!", "こんにちは!", "bounjour!", "안녕하세요!"];

  const updateLanguge = () => {
    setHelloLanguage(helloLanguage + 1);
    if(helloLanguage == languages.length - 1){
      setHelloLanguage(0);
    }
  }

  return <div className="text-white inset-center text-center absolute z-20 select-none">
      <motion.div drag dragConstraints={{left: -500, right: 500, top: -300, bottom: 300}} onClick={() => updateLanguge()} className='text-7xl font-bold p-4 mix-blend-difference z-50'>{languages[helloLanguage]}</motion.div>
      <div  className='text-5xl p-4 font-medium z-20'>I'm Lucas</div>
      {/* <div className='text-2xl p-4'>I'm a student and software developer</div> */}
      {/* <div className='text-2xl p-4'>I'm a student and software developer</div> */}
      <div className='text-2xl p-4'>
        <Typewriter 
            options={{
              strings: ['i\'m a software developer and student.', 'i have experience with mobile and web development.', 'click on the tabs to learn more.'],
              autoStart: true,
              loop: true,
              deleteSpeed: 'natural',
              delay: 100
            }}
          />
      </div>
      
      {/* <div className='text-xl font-light'>click to reveal</div> */}
  </div>;
}



export default CenterText;
