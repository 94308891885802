import React from 'react';
import { motion } from 'framer-motion';
import { useRecoilState } from "recoil";
import { pageState } from '../../atoms/pageAtom';




function AboutBlock() {

  const [currentPage, setCurrentPage] = useRecoilState(pageState);


  return <motion.div 
          animate={{opacity: 1}}
          initial={{opacity: 0}}
          transition={{duration: 1}}
          
      className='z-30 space-y-4'>
      <div className='text-4xl pb-7'>A little more about me...</div>
      <div className='text-xl'>I'm Lucas, a software developer and computer science major at <a href='https://www.cs.utexas.edu/' target="_blank" className='text-burnt-orange hover:opacity-80'>UT Austin</a>.</div>
      <div className='text-xl'>I have over 4 years of experience now working in software development, participating in <a href='https://devpost.com/lucaszcai' target="_blank" className='text-hint-blue hover:opacity-80'>hackathons</a>
      , building out <a onClick={() => setCurrentPage(2)} className='text-hint-blue hover:opacity-80'>personal projects</a>, and working at <a href='https://petcodeusa.com/' target="_blank" className='text-hint-blue hover:opacity-80'>startups</a>.</div>
      <div className='text-xl'>
        My main skill has been app development (Flutter, React Native).
        but I also have experience building web apps using React, VR
        platforms using Unity, and I’m currently learning more about 
        AI, Web3, and a little bit of hardware.</div>
      <div className='text-xl'>Apart from coding, I love learning (about <a href="https://en.wikipedia.org/wiki/Special:Random" target="_blank" className='text-hint-blue hover:opacity-80'>anything</a> really), 
          making music, reading, and playing video games.</div>
  </motion.div>;
}

export default AboutBlock;
