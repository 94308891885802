import React from 'react';
import AboutBlock from './blocks/AboutBlock';
import ContactBlock from './blocks/ContactBlock';
import { useRecoilValue } from "recoil";
import { pageState } from '../atoms/pageAtom';
import ProjectsBlock from './blocks/ProjectsBlock';


function Blocks() {

    const page = useRecoilValue(pageState);


  return <div className='h-screen w-screen overflow-hidden absolute bottom-0 left-0 flex flex-row items-center justify-evenly'>
      <div className='w-1/2 p-5 z-20'>
        {
            page == 1 ? <AboutBlock/> : <div></div>
        }
      </div>
      <div className='w-1/2 p-5 z-20 duration-500'>
        {
            page == 0 || page == 1 ? <div></div> : page == 3 || page == 4 ? <ContactBlock/> : <ProjectsBlock/>
        }
      </div>
  </div>;
}

export default Blocks;
