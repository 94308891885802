import React from 'react';
import { motion } from 'framer-motion';
import petcodelogo from '../../imgs/petcodelogo.png';
import aloislogo from '../../imgs/aloislogo.png';

function ProjectsBlock() {
  return <motion.div 
          animate={{opacity: 1}}
          initial={{opacity: 0}}
          transition={{duration: 1}}
          
      className='z-30 space-y-4'>
      <div className='flex flex-row p-4'>
        <div className='flex flex-col space-y-2'>
          <div className='text-4xl font-bold'>PetCode</div>
          <div>PetCode is a pet management system that takes 
              the stress out of pet ownership by allowing 
              owners to centralize their pet's data. With 
              PetCode, managing pet's health, social needs, 
              and safety has never been easier.
          </div>
        </div>

        <div className='rounded-xl bg-white '>
          <img src={petcodelogo} alt="petcode logo"/>
        </div>
      </div>


        <div className='flex flex-row p-4'>
          <div className='flex flex-col space-y-2'>
            <div className='text-4xl font-bold'>Alois Software</div>
            <div>Alois Software is a community platform built for
                Alzheimer’s Caretakers to share their stories and
                gain advice. The Alois AI allows for users to
                quickly find the answers they’re looking for.
            </div>
          </div>

          <div className='h-50 w-50 rounded-xl bg-white shadow-xl flex justify-center items-center '>
            <img className='mx-auto my-auto' src={aloislogo} alt="alois logo"/>
          </div>
        </div>




  </motion.div>;




}

export default ProjectsBlock;
