import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useRecoilState } from "recoil";
import { pageState } from '../atoms/pageAtom';

function CoverSlide() {

    const slideControl = useAnimation(); 

    const slideVariants = {
        left: {x:-window.innerWidth/2, },
        right: {x:window.innerWidth/2},
        center: {x:0},
    }

    const [currentPage, setCurrentPage] = useRecoilState(pageState);

    useEffect(() => {
        console.log("from slide: " + currentPage);
        if(currentPage == 1){
            slideControl.start("right");
        }
        if(currentPage == 2 || currentPage == 3){
            slideControl.start("left");
        }
        if(currentPage == 0){
            slideControl.start("center");
        }
    }, [currentPage]);


  return <motion.div 
            className="bg-black h-screen w-screen overflow-hidden absolute bottom-0 left-0 z-10"
            // animate={{x: window.innerWidth, y: window.innerHeight}}
            // transition={{duration: 2}}
            initial={{x: 0, y: 0}}
            // animate={{x:window.innerWidth / 2}}
            variants={slideVariants}
            // animate={"center"}
            animate={slideControl}
            // transition={"easeTransition"}
            transition={{
                type: 'spring',
                stiffness: 50
            }}
            onClick={() => setCurrentPage(0)}
            // drag
            // dragConstraints={{left: -(window.innerWidth - 100), right: window.innerWidth - 100, bottom: window.innerHeight - 100, top: -(window.innerHeight - 100), }}
            // dragConstraints={{left: 0, right: 0, top: 0, bottom: 0}}
            // dragElastic={0.9}
            >
    
    </motion.div>

}



export default CoverSlide;
