import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { pageState } from '../atoms/pageAtom';
import { useRecoilState } from "recoil";
import Confetti from 'react-confetti';


function CornerText() {

    const [currentPage, setCurrentPage] = useRecoilState(pageState);
    const [confettiOn, setConfettiOn] = useState(false);
    const [easterEggClicks, setEasterEggClicks] = useState(6);

    const numberControl = useAnimation(); 

    const updatePage = (page) => {
        if(page != currentPage){
            setCurrentPage(page);
        }
        else{
            setCurrentPage(0);
        }
    }

    const updateEasterEgg = () => {
        setEasterEggClicks(easterEggClicks - 1);
        numberControl.start({opacity: [1, 0]});
        if(easterEggClicks == 1){
            setConfettiOn(!confettiOn);
        }
        if(easterEggClicks == 0){
            setEasterEggClicks(5);
        }
    }

    const easterEggClick = () => {
        updatePage(0);
        updateEasterEgg();
    }

  return <div className='text-white text-2xl h-screen w-screen absolute select-none'>
          {
              confettiOn ? <Confetti  /> : <div></div>
          }
      {
          currentPage === 1 ?
              <div className='text-black duration-500'>
                <motion.div onClick={() => easterEggClick()} whileHover={{scale:1.1}} className='absolute bottom-10 left-10 z-50'>lucas cai</motion.div>
                <motion.div onClick={() => updatePage(1)} whileHover={{scale:1.1}} className='absolute top-10 left-10 z-50'>about</motion.div>
              </div>
          :
          <div className='text-white duration-500'>
            <motion.div onClick={() => easterEggClick()} whileHover={{scale:1.1}} className='absolute bottom-10 left-10 z-50'>lucas cai</motion.div>
            <motion.div onClick={() => updatePage(1)} whileHover={{scale:1.1}} className='absolute top-10 left-10 z-50'>about</motion.div> 
            <motion.div
                whileHover={{scale:1.1}} animate={numberControl} initial={{opacity: 0}} transition={{duration: 1}}
                className='absolute bottom-10 left-48 text-text-gray text-xs z-50'>
                    {easterEggClicks == 0 ? confettiOn ?  'party mode: on'  : 'party mode: off' : easterEggClicks}
            </motion.div>
          </div>
      }

      {
          currentPage == 2 || currentPage == 3 ? 
            <div className='text-black'>
                <motion.div onClick={() => updatePage(2)} whileHover={{scale:1.1}} className='absolute top-10 right-10 z-50'>projects</motion.div> 
                <motion.div onClick={() => updatePage(3)} whileHover={{scale:1.1}} className='absolute bottom-10 right-10 z-50'>contact</motion.div> 
            </div>
            :
            <div className='text-white duration-500'>
                <motion.div onClick={() => updatePage(2)} whileHover={{scale:1.1}} className='absolute top-10 right-10 z-50'>projects</motion.div> 
                <motion.div onClick={() => updatePage(3)} whileHover={{scale:1.1}} className='absolute bottom-10 right-10 z-50'>contact</motion.div> 
            </div>
      }
  </div>;
}



export default CornerText;
